<template>
  <!--begin::Freelancers index-->
  <div class="row gy-5 g-xl-8">
    <Freelancers widget-classes="card-xxl-stretch mb-5 mb-xl-8"></Freelancers>
  </div>
  <!--end::Freelancers index-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import Freelancers from "@/components/freelancers/Index.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "FreelancersView",
  components: {
    Freelancers
  },
  mounted() {
    setCurrentPageTitle("Freelancers");
  }
});
</script>
