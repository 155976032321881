<template>
  <div class="card">
    <div class="card mb-5 mb-xl-8">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1"
            >Freelancers Overview</span
          >

          <span class="text-muted mt-1 fw-bold fs-7"
            >Overview of all current freelancers</span
          >
        </h3>
        <div class="row">
          <div class="col-6 col-sm">
            <SkillsDropdown
              :placeholder="'Select skill category'"
              @change="updateSearchBySkills"
              v-model="selectedSkill"
            ></SkillsDropdown>
          </div>
          <div class="col-6 col-sm mb-4 mb-sm-0">
            <BranchesDropdown
              :placeholder="'Filter by Branch'"
              ref="branchesDropdown"
              @change="updateSearchByBranch"
            ></BranchesDropdown>
          </div>
          <div class="col-12 col-sm">
            <SearchField
              :placeholder="'Search by Name'"
              @changeSearch="updateSearchByTextInput"
            ></SearchField>
          </div>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="d-flex justify-content-center " v-if="loading">
          <div
            class="spinner-border text-primary m-20 spinner-size-increase"
            role="status"
          ></div>
        </div>
        <el-empty
          v-else-if="getFreelancersList.length == 0"
          :description="
            'OOPS... Unfortunately no freelancers matching your filters were found.'
          "
        ></el-empty>
        <FreelancersDataTable
          ref="freelancersTable"
          v-else
          @viewFreelancer="handleViewFreelancer"
          :tableData="getFreelancersList"
          @changeSort="updateTableSort"
        ></FreelancersDataTable>
      </div>
      <Pagination
        :currentPage="pagination.currentPage"
        :totalPages="pagination.lastPage"
        @changeCurrentPage="changeCurrentPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import SearchField from "@/components/general/SearchField.vue";
import BranchesDropdown from "@/components/dropdown/BranchesDropdown.vue";
import SkillsDropdown from "@/components/dropdown/SkillsDropdown.vue";
import FreelancersDataTable from "@/components/freelancers/FreelancersDataTable.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import {generateBaseUrl} from "@/components/users/helpers";

export default defineComponent({
  name: "UsersDataTable",
  data() {
    return {
      pagination: {},
      selectedSkill: ""
    };
  },
  components: {
    SearchField,
    BranchesDropdown,
    SkillsDropdown,
    FreelancersDataTable,
    Pagination
  },
  methods: {
    ...mapActions("FreelancersModule", [
      "fetchData",
      "setCurrentPageNumber",
      "setSearchBySkill",
      "setSearchString",
      "setSearchByBranch",
      "setLoading",
      "fetchFreelancersWithSearchedSkills",
      "resetPageNumber",
      "setSortParams",
      "resetSortParams",
      "resetState"
    ]),
    changeCurrentPage(pageNumber) {
      this.setCurrentPageNumber(pageNumber);
      this.fetchData();
    },
    updateSearchBySkills(skillID) {
      this.fetchFreelancersWithSearchedSkills({skill: skillID, reset: true});
    },
    updateSearchByTextInput(searchInput) {
      if (this.$route.query.search !== searchInput) {
        this.setSearchString(searchInput);
        this.resetPageNumber();
        this.fetchData();
      }
    },
    updateSearchByBranch(branchID) {
      if (this.$route.query.branch !== branchID) {
        this.setSearchByBranch(branchID);
        this.resetPageNumber();
        this.fetchData();
      }
    },
    updateTableSort(sortParams) {
      this.setSortParams(sortParams);
      this.fetchData();
    },
    clearQueryParams() {
      const query = Object.assign({}, this.$route.query);
      delete query.page;
      delete query.search;
      delete query.branch;
      delete query.skill;
      this.$router.replace({ query });
    },
    handleViewFreelancer(userId) {
      const url = generateBaseUrl(userId, this.getPaginationData.currentPage, this.getSearchString, 'freelancers') +
          `${this.getBranchID !== '' ? `&branch=${this.getBranchID}` : ''}` +
          `${this.selectedSkill !== "" ? `&skill=${this.selectedSkill}` : ''}`;
      this.$router.push(url);
    },
    handleRouteSkillParam(skill) {
        if (skill.trim() !== "" && !isNaN(skill)) {
          this.selectedSkill = parseInt(skill);
          this.fetchFreelancersWithSearchedSkills({skill: skill, reset: false});
        }
    }
  },
  computed: {
    ...mapGetters("FreelancersModule", [
      "loading",
      "getBranchID",
      "getPaginationData",
      "getSearchString",
      "getFreelancersList",
      "getPaginationData"
    ])
  },
  mounted() {
    this.resetSortParams();
    if (this.$route.query.page) this.setCurrentPageNumber(this.$route.query.page)
    if (this.$route.query.branch) {
      this.setSearchByBranch(this.$route.query.branch);
      this.$refs.branchesDropdown.populate(parseInt(this.$route.query.branch));
    }
    if (this.$route.query.search) this.setSearchString(this.$route.query.search);
    if (this.$route.query.skill) {
      this.setLoading(true);
      this.handleRouteSkillParam(this.$route.query.skill);
    } else {
      this.fetchData();
    }
    this.clearQueryParams();
    if (this.$can("view_only_own_company_jobs", "all")) {
      this.$refs.freelancersTable.changeSortBy("shifts_completed_company");
    }
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
</script>
