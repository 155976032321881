
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "email-link",
  props: {
    email: {
      type: String as PropType<string>,
      required: true
    },
    classes: {
      type: String as PropType<string>,
      required: false
    }
  }
});
