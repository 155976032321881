<template>
  <el-select
    v-model="value"
    clearable
    size="large"
    filterable
    placeholder="Select"
    class="w-100"
  >
    <el-option
      v-for="item in skills"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "SkillsDropdown",
  data() {
    return {
      value: null
    };
  },
  methods: {
    ...mapActions("ListsModule", ["fetchSkills"])
  },
  mounted() {
    this.fetchSkills();
  },
  computed: {
    ...mapGetters("ListsModule", ["skills"])
  }
});
</script>
