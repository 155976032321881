<template>
  <div class="table-responsive">
    <table
      class="
            table table-hover table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th @click="changeSortBy('name')" class="min-w-140px">
            User<SortingArrow
              v-if="sortParams.sortedBy == 'name'"
              :isOrderAsc="sortParams.isOrderAsc"
            ></SortingArrow>
          </th>
          <th @click="changeSortBy('approved_hours')" class="min-w-90px">
            Hours Worked<SortingArrow
              v-if="sortParams.sortedBy == 'approved_hours'"
              :isOrderAsc="sortParams.isOrderAsc"
            ></SortingArrow>
          </th>
          <th
            @click="changeSortBy('tardiness')"
            class="min-w-160px "
            v-if="$can('can_view_tardiness', 'all')"
          >
            Tardiness<SortingArrow
              v-if="sortParams.sortedBy == 'tardiness'"
              :isOrderAsc="sortParams.isOrderAsc"
            ></SortingArrow>
          </th>
          <th @click="changeSortBy('shifts_completed')" class="min-w-180px ">
            Shifts Completed<SortingArrow
              v-if="sortParams.sortedBy == 'shifts_completed'"
              :isOrderAsc="sortParams.isOrderAsc"
            ></SortingArrow>
          </th>
          <th
            class="min-w-90px"
            @click="changeSortBy('shifts_completed_company')"
            v-if="$can('view_only_own_company_jobs', 'all')"
          >
            For company<SortingArrow
              v-if="sortParams.sortedBy == 'shifts_completed_company'"
              :isOrderAsc="sortParams.isOrderAsc"
            ></SortingArrow>
          </th>
          <th class="min-w-100px ">Skills</th>
          <th class="min-w-140px text-end">Branches</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in tableData" :key="user.id">
          <td>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-45px me-5">
                <Img :src="user.photo_url" alt="" />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a
                  @click="$emit('viewFreelancer', user.id)"
                  class="text-dark fw-bolder text-hover-primary fs-6"
                  >{{ user.name }}</a
                >
                <PhoneLink
                  v-if="$can('can_view_user_contacts', 'all')"
                  :phone="user.phone"
                  css-classes="text-muted fw-bold d-block fs-7 text-hover-primary"
                ></PhoneLink>
                <EmailLink
                  v-if="$can('can_view_user_contacts', 'all')"
                  :classes="
                    'text-muted fw-bold text-muted d-block fs-7 text-hover-primary'
                  "
                  :email="user.email"
                ></EmailLink>
              </div>
            </div>
          </td>
          <td>
            <span class="text-dark fw-bolder fs-6">{{
              user.approved_hours == null ? "" : user.approved_hours + " hrs"
            }}</span>
          </td>
          <td v-if="$can('can_view_tardiness', 'all')">
            <span class="text-dark fw-bolder fs-6">{{ user.tardiness }}</span>
          </td>
          <td>
            <span class="text-dark fw-bolder fs-6">{{
              user.shifts_completed
            }}</span>
          </td>
          <td v-if="$can('view_only_own_company_jobs', 'all')">
            <span class="text-dark fw-bolder fs-6">{{
              user.shifts_completed_company
            }}</span>
          </td>
          <td>
            <span
              class="badge rounded-pill bg-primary text-wrap ms-1 mb-2 "
              v-for="skill in user.skills"
              :key="skill.id"
              >{{ skill.name }}</span
            >
          </td>
          <td class="text-end">
            <span
              class="badge rounded-pill bg-success text-wrap ms-1 mb-2"
              v-for="branch in user.branches"
              :key="branch.id"
              >{{ branch.name }}</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Img from "@/components/general/Img.vue";
import SortingArrow from "@/components/general/sortingArrow.vue";
import PhoneLink from "@/components/general/PhoneLink.vue";
import EmailLink from "@/components/general/EmailLink.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "FreelancersDataTable",
  data() {
    return {
      sortParams: {
        sortedBy: "",
        isOrderAsc: false
      }
    };
  },
  components: {
    Img,
    SortingArrow,
    PhoneLink,
    EmailLink
  },
  props: {
    tableData: Object
  },
  computed: {
    ...mapGetters("FreelancersModule", [
      "getSortData",
      "getPaginationData",
      "getSearchString",
      "getBranchID"
    ])
  },
  methods: {
    changeSortBy(userSelectedSortValue) {
      if (userSelectedSortValue == this.sortParams.sortedBy) {
        this.sortParams.isOrderAsc = !this.sortParams.isOrderAsc;
      } else {
        this.sortParams.isOrderAsc = false;
      }
      this.sortParams.sortedBy = userSelectedSortValue;
      this.$emit("changeSort", this.sortParams);
    }
  },
  mounted() {
    this.sortParams = this.getSortData;
  }
});
</script>
